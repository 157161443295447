.payslipContainer {
	background-color: #fff;
	overflow: hidden;
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 2px;

	color: #414042 !important;
}
.pdf-payslip-company-container {
	display: flex;
	flex-direction: row;
}
.pdf-payslip-company-info {
	float: left;
	width: 95%;
	border-bottom: 0.05px solid #414042;
}
.pdf-payslip-company-logo {
	float: right;
}

.pdf-payslip-company-info {
	float: left;
	width: 95%;
}

.pdf-payslip-employee-info {
	margin: 12px;
	float: left;
	width: 30%;
}
.pdf-payslip-data-info {
	float: right;
	display: flex;
	flex-direction: column;
	width: 30%;
}

.pdf-payslip-data-info .row .col-md-4 {
	display: flex;
	flex-direction: row;
	float: left;
	width: 30%;
}
.pdf-payslip-data-info .row .text-right {
	display: flex;
	flex-direction: row;
	padding-left: 32px;
}
